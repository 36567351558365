export default {
    list: {
		data: null,
		links: null,
		meta: null,
		current_page:null,
        prodcutCategoeis: null
	},
	deleted_list: {
		data: null,
		links: null,
		meta: null,
		current_page:null,
        prodcutCategoeis: null
	}
}
