export default [
	{
        path: '/crowd-funding',
        component: () => import('../components/CrowdFundingParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/crowd-funding',
                component: () => import('../components/CrowdFunding.vue'),
                name: 'crowd-funding',
            },
            {
                path: '/fund-raiser-form',
                component: () => import('../components/FundRaiserForm.vue'),
                name: 'fund-raiser-form',
                meta: {                  
                }
            },
            {
                path: '/crowd-funding/funding-info',
                component: () => import('../components/FundingInfo.vue'),
                name: 'funding-info',
                meta: {                  
                }
            },
        ]
    },
    
   
]