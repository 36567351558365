export const store = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/exhibition', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getExhibitionList = ({ commit, dispatch,state }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/exhibition'+ query).then((response) => {
        commit('setExhibitions', response.data);
        return true;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const applyForExhibition = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/apply-for-exhibition', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getExhibitionOrganizerList = ({ commit, dispatch,state }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/exhibition-organizer'+ query).then((response) => {
        commit('setExhibitionsOrganizer', response.data);
        return true;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getExhibitionAttendeeList = ({ commit, dispatch,state }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/exhibition-attendee'+ query).then((response) => {
        commit('setExhibitionsAttendee', response.data);
        return true;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const cancelSubscription = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/cancel-subscription', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getExhibitionExhibitorList = ({ commit, dispatch,state }, query = '') => {
    
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/exhibition-exhibitor'+ query).then((response) => {
        commit('setExhibitionsExhibitor', response.data);
        return true;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getExhibitionById = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/exhibition/'+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const changeSubscribedExhibitorsStatus = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/change-exhibitor-status/'+payload.id,payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}