export const getSaleOrdersList = ({ commit, dispatch }, query = '') => {
    query = query ? query+'&ready_for=Sale' : '?ready_for=Sale';
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/orders-list-seller'+ query).then((response) => {
        commit('SET_ORDER_LIST_DATA', response.data.data);
        return response.data.data;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const getOrderDetails = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/orders-details/'+ query.payload.order_id).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const getRentOrdersList = ({ commit, dispatch }, query = '') => {
    query = query ? query+'&ready_for=Rent' : '?ready_for=Rent';
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/orders-list-seller'+ query).then((response) => {
        commit('SET_ORDER_LIST_DATA', response.data.data);
        return response.data.data;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}


export const eventDiscountAcceptReject = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/eventDiscountAcceptReject',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

export const requestPayment = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/change-order-status',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const orderShipped = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/order-shipped',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const returnRequestAcceptReject = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/returnRequestAcceptReject',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

export const receviedReturnOrder = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/return-order-recevied',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}