export const getExhibitionCategories = (state) => {
	return state.exhibitionCategories;
}
export const getExhibitionTypes = (state) => {
	return state.exhibitionType;
}
export const getExhibition = (state) => {
	return state.exhibition.data;
}
export const getExhibitionListMeta = (state) => {
	return state.exhibition.meta;
}
export const getExhibitionListLinks = (state) => {
	return state.exhibition.links;
}
export const getExhibitionCurrentPage = (state) => {
	return state.exhibition.current_page;
}
export const getExhibitionDataCount = (state) => {
	return state.exhibition.count;
}
export const getExhibitionOrganizer = (state) => {
	return state.exhibitionOrganizer.data;
}
export const getExhibitionOrganizerListMeta = (state) => {
	return state.exhibitionOrganizer.meta;
}
export const getExhibitionOrganizerListLinks = (state) => {
	return state.exhibitionOrganizer.links;
}
export const getExhibitionOrganizerCurrentPage = (state) => {
	return state.exhibitionOrganizer.current_page;
}
export const getExhibitionOrganizerDataCount = (state) => {
	return state.exhibitionOrganizer.count;
}
export const getExhibitionAttendee = (state) => {
	return state.exhibitionAttendee.data;
}
export const getExhibitionAttendeeListMeta = (state) => {
	return state.exhibitionAttendee.meta;
}
export const getExhibitionAttendeeListLinks = (state) => {
	return state.exhibitionAttendee.links;
}
export const getExhibitionAttendeeCurrentPage = (state) => {
	return state.exhibitionAttendee.current_page;
}
export const getExhibitionAttendeeDataCount = (state) => {
	return state.exhibitionAttendee.count;
}
export const getExhibitionExhibitor = (state) => {
	return state.exhibitionExhibitor.data;
}
export const getExhibitionExhibitorListMeta = (state) => {
	return state.exhibitionExhibitor.meta;
}
export const getExhibitionExhibitorListLinks = (state) => {
	return state.exhibitionExhibitor.links;
}
export const getExhibitionExhibitorCurrentPage = (state) => {
	return state.exhibitionExhibitor.current_page;
}
export const getExhibitionExhibitorDataCount = (state) => {
	return state.exhibitionExhibitor.count;
}
export const getExhibitorUser = (state) => {
	return state.exhibitorUser.data;
}
export const getExhibitorUserListMeta = (state) => {
	return state.exhibitorUser.meta;
}
export const getExhibitorUserListLinks = (state) => {
	return state.exhibitorUser.links;
}
export const getExhibitorUserCurrentPage = (state) => {
	return state.exhibitorUser.current_page;
}
export const getExhibitorUserDataCount = (state) => {
	return state.exhibitorUser.count;
}
export const getAttendeeUser = (state) => {
	return state.attendeeUser.data;
}
export const getAttendeeUserListMeta = (state) => {
	return state.attendeeUser.meta;
}
export const getAttendeeUserListLinks = (state) => {
	return state.attendeeUser.links;
}
export const getAttendeeUserCurrentPage = (state) => {
	return state.attendeeUser.current_page;
}
export const getAttendeeUserDataCount = (state) => {
	return state.attendeeUser.count;
}