export default [
	{
        path: '/cart',
        component: () => import('../components/CartParent.vue'),
        children:[
            {
                path: '/cart',
                component: () => import('../components/Cart.vue'),
                name: 'cart',
                meta: {}
            },
            
            {
                path: '/cart/:id',
                component: () => import('../components/partials/CartInfo.vue'),
                name: 'cart-id',
                    
            },
            {
                path: '/buy-product',
                component: () => import('../components/partials/CartBuyProduct.vue'),
                name: 'buy-product',
                    
            },
        ]
    },
    

]