export const SET_ORDER_LIST_DATA = (state, data) => {
	state.list.data = data.data;
	state.list.current_page = data.current_page;
	state.list.links = data.links
	state.list.meta = {
		current_page: data.current_page,
		from: data.from,
		to:  data.to,
		total: data.total,
		perPage: data.per_page
	};
}