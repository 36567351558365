export const getInviteRefferals = (state) => {
	return state.showInviteRefferals
}

export const getDeleteRefferal = (state) => {
	return state.showDeleteRefferal
}
export const getReferralListData = (state) => {
	return state.referralList.data;
}
export const getReferralListLinks = (state) => {
	return state.referralList.links;
}
export const getReferralListMeta = (state) => {
	return state.referralList.meta;
}