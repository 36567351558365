export default {
	crowdfunding:{
		data: null,
		links: null,
		meta: null,
		current_page:null
	},
	cancelOrder:{
		data: null,
		links: null,
		meta: null,
		current_page:null
	},
	cancelOrderReturnToBuyerFundsInfo:{
		amount: null,
		currncy: null,
	},
}
