export const getCrowdFundingList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/crowd-funding-list'+ query).then((response) => {
        commit('setCrowdFunding', response.data);
        return true;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const cancelCrowdFunding = ({ commit, dispatch }, {payload,context}) => {
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + 'api/crowd-funding/'+ payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        context.gIndicator('stop');
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}