export const setExhibitions = (state, exhibition) => {
	state.exhibition.data = exhibition.list.data;
	state.exhibition.count = exhibition.count;
	state.exhibition.current_page = exhibition.list.current_page;
	state.exhibition.links = exhibition.list.links
	state.exhibition.meta = {
		current_page: exhibition.list.current_page,
		from: exhibition.list.from,
		to:  exhibition.list.to,
		total: exhibition.list.total,
		perPage: exhibition.list.per_page
	};
}
export const setRole = (state, value) => {
	state.role = value;
}
export const setExhibitionsOrganizer = (state, exhibition) => {
	state.exhibitionOrganizer.data = exhibition.list.data;
	state.exhibitionOrganizer.count = exhibition.count;
	state.exhibitionOrganizer.current_page = exhibition.list.current_page;
	state.exhibitionOrganizer.links = exhibition.list.links
	state.exhibitionOrganizer.meta = {
		current_page: exhibition.list.current_page,
		from: exhibition.list.from,
		to:  exhibition.list.to,
		total: exhibition.list.total,
		perPage: exhibition.list.per_page
	};
}
export const setExhibitionsAttendee = (state, exhibition) => {
	state.exhibitionAttendee.data = exhibition.list.data;
	state.exhibitionAttendee.count = exhibition.count;
	state.exhibitionAttendee.current_page = exhibition.list.current_page;
	state.exhibitionAttendee.links = exhibition.list.links
	state.exhibitionAttendee.meta = {
		current_page: exhibition.list.current_page,
		from: exhibition.list.from,
		to:  exhibition.list.to,
		total: exhibition.list.total,
		perPage: exhibition.list.per_page
	};
}
export const setExhibitionsExhibitor = (state, exhibition) => {
	state.exhibitionExhibitor.data = exhibition.list.data;
	state.exhibitionExhibitor.count = exhibition.count;
	state.exhibitionExhibitor.current_page = exhibition.list.current_page;
	state.exhibitionExhibitor.links = exhibition.list.links
	state.exhibitionExhibitor.meta = {
		current_page: exhibition.list.current_page,
		from: exhibition.list.from,
		to:  exhibition.list.to,
		total: exhibition.list.total,
		perPage: exhibition.list.per_page
	};
}

export const setExhibitorUserList = (state, exhibition) => {
	state.exhibitorUser.data = exhibition.data;
	state.exhibitorUser.count = exhibition.count;
	state.exhibitorUser.current_page = exhibition.current_page;
	state.exhibitorUser.links = exhibition.links
	state.exhibitorUser.meta = {
		current_page: exhibition.current_page,
		from: exhibition.from,
		to:  exhibition.to,
		total: exhibition.total,
		perPage: exhibition.per_page
	};
}
export const setAttendeeUserList = (state, exhibition) => {
	state.attendeeUser.data = exhibition.data;
	state.attendeeUser.count = exhibition.count;
	state.attendeeUser.current_page = exhibition.lcurrent_page;
	state.attendeeUser.links = exhibition.links
	state.attendeeUser.meta = {
		current_page: exhibition.current_page,
		from: exhibition.from,
		to:  exhibition.to,
		total: exhibition.total,
		perPage: exhibition.per_page
	};
}