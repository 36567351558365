export const getProductCategoiesList = (state) => {
	return state.list.productCategoeis;
}

export const getProductSubCategoiesList = (state) => {
    return state.list.productSubCategoeis
}

export const getProductUsersCountryList = (state) => {
    return state.list.productUserCountry
}

export const getListData = (state) => {
	return state.list.data;
}

export const getListLinks = (state) => {
	return state.list.links;
}

export const getListMeta = (state) => {
	return state.list.meta;
}