export default {
	exhibitionCategories:[
		{'id': 1, 'name' : 'Virtual Exhibition', 'slug' : 'virtual-exhibition'},
		{'id': 2, 'name' : 'Physical Exhibition', 'slug' : 'physical-exhibition'},
		{'id': 3, 'name' : 'Both', 'slug' : 'both'}
	],
	exhibitionType:[
		{'id': 1, 'name' : 'Art Exhibition', 'slug' : 'art'},
		{'id': 2, 'name' : 'Photo Exhibition', 'slug' : 'photo'},
		{'id': 3, 'name' : 'Design Exhibition', 'slug' : 'design'},
		{'id': 4, 'name' : 'All', 'slug' : 'all'}
	],
	exhibition:{
		data: null,
		links: null,
		meta: null,
		current_page:null,
		count:null
	},
	exhibitionOrganizer:{
		data: null,
		links: null,
		meta: null,
		current_page:null,
		count:null
	},
	exhibitionAttendee:{
		data: null,
		links: null,
		meta: null,
		current_page:null,
		count:null
	},
	exhibitionExhibitor:{
		data: null,
		links: null,
		meta: null,
		current_page:null,
		count:null
	},
	exhibitorUser:{
		data: null,
		links: null,
		meta: null,
		current_page:null,
		count:null
	},
	attendeeUser:{
		data: null,
		links: null,
		meta: null,
		current_page:null,
		count:null
	},
	role:'',
}
