export default [
	{
		path: '/refferals-list',
		component: () => import('../components/RefferalsList.vue'),
		name: 'refferals-list',
		meta: {                  
		},

    },

]