import { createRouter, createWebHistory } from 'vue-router'
import { routes as routes } from '../app/index'

import PageNotFound from '../views/PageNotFound.vue'

const otherRoutes = [
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: PageNotFound
    },
];


for (let i = 0; i < otherRoutes.length; i++){
    routes.push(otherRoutes[i]);
  }

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router