export const showCancelSubscription = (state, flag) => {
	state.showCancelSubscription = flag
}

export const showDeletePayment = (state, flag) => {
	state.showDeletePayment = flag
}

export const setCrowdFunding = (state, crowdfunding) => {
	state.crowdfunding.data = crowdfunding.list.data;
	state.crowdfunding.current_page = crowdfunding.list.current_page;
	state.crowdfunding.links = crowdfunding.list.links
	state.crowdfunding.meta = {
		current_page: crowdfunding.list.current_page,
		from: crowdfunding.list.from,
		to:  crowdfunding.list.to,
		total: crowdfunding.list.total,
		perPage: crowdfunding.list.per_page
	};
}