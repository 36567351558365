<template>
    <div>
        <header class="relative ">
            <div class="absolute inset-y-0 w-full">
                <div class="fixed w-full z-30 shadow-lg flex items-center justify-between bg-black bg-opacity-75">
                    <div>
                        <img src="/images/logo.webp" class="w-32 md:w-40 xl:w-60 h-auto bg-white py-2 px-4 md:px-8 shadow-logoShadow rounded-customRadius flex items-center object-contain">
                    </div>                
                    <div class="">
                        <div class="flex text-white px-2 lg:px-4 2xl:px-8 items-center gap-1 xl:gap-5 uppercase font-semibold text-small lg:text-xs xl:text-sm 2xl:text-base tracking-wider">
                            <router-link to="/" active-class="active" class="hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-2 py-1.5 rounded" >Home</router-link>
                            <a href="javascript:void(0)" @click="redirectBtn('/search')" active-class="active" class="hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-2 py-1.5 rounded" >Search</a>
                            <div class="hidden md:block cursor-pointer">
                                <div class="flex items-center gap-2 hover:bg-opacity-50 hover:bg-bgBlue px-2 py-1.5 rounded "
                                 @click.native="dropGallery = !dropGallery"
                                 v-bind:class="{ 'bg-bgBlue text-white p-1.5 rounded': isActive('art-gallery') || isActive('art-users') || isActive('art-user-profile') || isActive('photo-gallery') || isActive('photographer-list') || isActive('photographer-profile') || isActive('frame-gallery') || isActive('frame-vendors') || isActive('frame-vendor-profile') || isActive('designers-gallery') || isActive('designers-list') || isActive('designers-profile')}"
                                 >
                                    <!-- <span v-if="galleryName">{{ galleryName }}</span> -->
                                    <span v-if="!isActive('art-gallery') && !isActive('art-user-profile') && !isActive('art-users') && !isActive('photo-gallery') && !isActive('photographer-profile') && !isActive('frame-gallery') && !isActive('frame-vendors') && !isActive('frame-vendor-profile') && !isActive('designers-gallery') && !isActive('designers-profile') && !isActive('designers-list')">Gallery</span>
                                    <span v-else-if="isActive('art-gallery') || isActive('art-user-profile') || isActive('art-users')">Art Gallery</span>
                                    <span v-else-if="isActive('photo-gallery') || isActive('photographer-profile')">Photo Gallery</span>
                                    <span v-else-if="isActive('frame-gallery') || isActive('frame-vendors') || isActive('frame-vendor-profile')">Frame Gallery</span>
                                    <span v-else-if="isActive('designers-gallery')  || isActive('designers-list') || isActive('designers-profile')">Design Gallery</span>
                                    <svg class="w-2 lg:w-3.5 flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropGallery }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill="white"/></svg>
                                </div>
                                <button v-if="dropGallery" @click="dropGallery = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                                <div v-if="dropGallery" class="relative">
                                    <ul class="absolute top-1 -left-2 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                        <li :class="{ current : galleryItem === galleryName }" class="hover:bg-bgBlue p-2.5 text-bgBlue hover:text-white rounded-md" 
                                        v-for="(galleryItem, index) in galleryList" :key="index">
                                            <a href="javascript:void(0)" @click="redirectBtn(galleryItem.link),dropGallery = !dropGallery" class="py-2.5 font-semibold" 
                                            >{{ galleryItem.name }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div class="hidden md:block cursor-pointer">
                                <div class="flex items-center gap-2 hover:bg-opacity-50 hover:bg-bgBlue px-2 py-1.5 rounded"  @click="dropService = !dropService"
                                v-bind:class="{ 'bg-bgBlue text-white p-1.5 rounded': isActive('sales-&-auction') || isActive('buy-&-rent') || isActive('custom-order') || isActive('order-now-art') || isActive('order-now-photo') || isActive('order-now-design') || isActive('exhibition') || isActive('exhibition-info') }">
                                    <!-- <span>{{ serviceName }}</span> -->
                                    <span v-if="!isActive('sales-&-auction') && !isActive('buy-&-rent') && !isActive('custom-order') && !isActive('order-now-art') &&!isActive('order-now-photo') && !isActive('order-now-design') && !isActive('exhibition') && !isActive('exhibition-info')">Servies</span>
                                    <span v-if="isActive('sales-&-auction') ">Sales / Auction</span>
                                    <span v-if="isActive('buy-&-rent')">Buy / Rent</span>
                                    <span v-if="isActive('custom-order') || isActive('order-now-art') || isActive('order-now-photo') || isActive('order-now-design')">Custom Order</span>
                                    <span v-if="isActive('exhibition') || isActive('exhibition-info')">Exhibition</span>

                                    <svg class="w-2 lg:w-3.5 flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropService }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill="white"/></svg>
                                </div>
                                <button v-if="dropService" @click="dropService = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                                <div v-if="dropService" class="relative">
                                    <ul class="absolute top-1 -left-2 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                        <li :class="{ current : serviceItem === serviceName }" class="hover:bg-bgBlue p-2.5 text-bgBlue hover:text-white rounded-md" v-for="(serviceItem, index) in serviceList" :key="index">
                                            <a href="javascript:void(0)" @click="redirectBtn(serviceItem.link),dropService = !dropService" class="py-2.5 font-semibold">{{ serviceItem.name }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div> 

                            <a href="javascript:void(0)" @click="redirectBtn('/crowd-funding')" active-class="active" class="nav-link hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-2 py-1.5 rounded" >Crowd Funding</a>
                            <a href="javascript:void(0)" @click="redirectBtn('/forum')" active-class="active" class="hidden md:block px-2 py-1.5 rounded hover:bg-opacity-50 hover:bg-bgBlue" >Forum</a>
                            
                            <router-link :to="{name:'artist-sign-in'}" v-if="!isAuthenticated" class="nav-link hover:bg-opacity-50 hover:bg-bgBlue px-2 py-1.5 rounded uppercase">Sign In</router-link >
                            
                            <button type="button" @click="logout" v-if="isAuthenticated" class="nav-link hover:bg-opacity-50 hover:bg-bgBlue px-2 py-1.5 rounded uppercase">Sign Out</button >

                            <div class="" v-if="isAuthenticated">
                                <div class="flex items-center rounded-full cursor-pointer" @click="dropUser = !dropUser">
                                    <img :src="profile_image" class="w-8 h-8  border border-white hover:border-bgBlue rounded-full">
                                </div>
                                <button v-if="dropUser" @click="dropUser = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-20"></button>  
                                <div v-if="dropUser" class="relative">
                                    <div class="absolute top-1.5 -left-2.5 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-40 z-30">
                                        <router-link :to="{name:'dashboard'}" class="hover:bg-bgBlue px-2.5 py-2.5 text-bgBlue hover:text-white rounded-md w-full flex" >Dashboad
                                        </router-link>
                                        <router-link :to="{name:'my-profile'}" class="hover:bg-bgBlue px-2.5 text-bgBlue py-2.5 hover:text-white rounded-md w-full flex" >Profile
                                        </router-link>
                                        <a href="javascript:void(0)" @click="redirectBtn('/')" class="hover:bg-bgBlue px-2.5 text-bgBlue py-2.5 hover:text-white rounded-md w-full flex" >Buyer Dashboard
                                        </a>
                                    </div>
                                </div>
                            </div> 


                            <div class="hover:bg-opacity-50 hover:bg-bgBlue px-2 py-1.5 rounded border border-white cursor-pointer" >
                                <div class="flex items-center gap-2" @click="dropLang = !dropLang">
                                    <svg class="w-2.5 lg:w-4 pointer-events-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path>
                                    </svg>
                                    <span>{{ langName }}</span>
                                    <svg class="w-2 lg:w-3.5 flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropLang }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill="white"/></svg>
                                </div>
                                <button v-if="dropLang" @click="dropLang = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>  
                                <div v-if="dropLang" class="relative">
                                    <ul class="absolute top-1.5 -left-2.5 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-28 z-30">
                                        <li :class="{ current : lang === langName }" class="hover:bg-bgBlue p-1.5 text-bgBlue hover:text-white rounded-md" v-for="(lang, index) in langList" :key="index" @click="selectLang(lang.name); dropLang = !dropLang">{{ lang.name }}
                                        </li>
                                    </ul>
                                </div>
                            </div> 

                            <div>
                                <router-link :to="{name:'cart'}" active-class="activeCart"  class="w-[40px] h-[40px] flex items-center justify-center">
                                    <svg class="fill-current w-[20px] lg:w-[25px] h-[25px] " fill="none" viewBox="0 0 32 25">
                                    <path fill="#fff" d="M31.1024 4.56337c-.0931-.12102-.2128-.21909-.3497-.28669-.1369-.06761-.2876-.10295-.4403-.10331H7.21243l-.45-1.43c-.04943-.15355-.13527-.29285-.2502-.40603-.11492-.11319-.25552-.19689-.4098-.24397l-4.1-1.260004C1.87636.794626 1.7439.781096 1.6126.79355c-.1313.012453-.25886.050646-.3754.112397-.23535.124713-.411531.337813-.48977.592423-.078239.2546-.052131.52986.072581.76522.124711.23536.337809.41154.592419.48978l3.59 1.1 4.58 14.47003-1.63 1.34-.13.13c-.4031.4667-.63148 1.0591-.64608 1.6756-.01459.6166.18551 1.2191.56608 1.7044.27256.3314.61891.5945 1.01134.7681.39243.1737.82005.2531 1.24863.2319h16.69c.2652 0 .5196-.1054.7071-.2929.1876-.1876.2929-.4419.2929-.7071 0-.2653-.1053-.5196-.2929-.7071-.1875-.1876-.4419-.2929-.7071-.2929H9.84243c-.11515-.004-.22735-.0375-.32574-.0975-.0984-.0599-.17967-.1442-.23597-.2448-.05629-.1005-.08571-.2139-.08541-.3291.00029-.1152.0303-.2284.08712-.3286l2.40997-2h15.43c.2285.0066.4523-.0652.6342-.2034.182-.1383.3111-.3348.3658-.5566l3.2-13.00003c.0304-.14909.0264-.30314-.0117-.45044s-.1094-.28396-.2083-.39956Z"/>
                                </svg>
                                </router-link>
                            </div>

                            <div class="block md:hidden">
                                <button type="button" @click="mobileHeader = !mobileHeader">
                                    <svg class="fill-current text-white w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- mobile header -->
            <div class="md:hidden" v-if="mobileHeader">
                <div class="opacity-25 fixed inset-0 z-50 bg-black"></div>
                <div>   
                    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 right-0 z-50 outline-none focus:outline-none">

                        <div class="flex w-full">
                            <div class="flex w-6/12"></div> 
                            <div class="w-6/12 flex">
                                <div class="w-full shadow-lg rounded-l-md h-screen relative flex  bg-white outline-none focus:outline-none mx-auto border-l-2 border-y-2 border-blue-200 relative">        
                                    <div class="flex flex-col gap-4 px-5 py-2">
                                        <a href="/" class="nav-link hover:bg-bgBlue px-2 py-1.5 rounded" :class="[{'bg-bgBlue text-white text-center' : isHomePage == 1}]">Home</a>
                                        <a href="javascript:void(0)" @click="redirectBtn('/search')" class="nav-link hover:bg-bgBlue px-2 py-1.5 rounded" :class="[{'bg-bgBlue text-white text-center' : isSearchPage == 1}]">Search</a>
                                        <div class="px-2 py-1.5 rounded cursor-pointer">
                                            <div class="flex items-center gap-2 text-black" 
                                            @click.native="dropGallery = !dropGallery"
                                            v-bind:class="{ 'bg-bgBlue text-white p-1.5 rounded': isActive('art-gallery') || isActive('art-users') || isActive('art-user-profile') || isActive('photo-gallery') || isActive('photographer-list') || isActive('photographer-profile') || isActive('frame-gallery') || isActive('frame-vendors') || isActive('frame-vendor-profile') || isActive('designers-gallery') || isActive('designers-list') || isActive('designers-profile')}">
                                               <span v-if="!isActive('art-gallery') && !isActive('art-user-profile') && !isActive('photo-gallery') && !isActive('photographer-profile') && !isActive('frame-gallery') && !isActive('frame-vendors') && !isActive('frame-vendor-profile') && !isActive('designers-gallery') && !isActive('designers-profile')">Gallery</span>
                                                <span v-if="isActive('art-gallery') || isActive('art-user-profile')">Art Gallery</span>
                                                <span v-if="isActive('photo-gallery') || isActive('photographer-profile')">Photo Gallery</span>
                                                <span v-if="isActive('frame-gallery') || isActive('frame-vendors') || isActive('frame-vendor-profile')">Frame Gallery</span>
                                                <span v-if="isActive('designers-gallery') || isActive('designers-profile')">Design Gallery</span>
                                                
                                                <svg class="w-2 lg:w-3.5 fill-current hover:text-white text-black flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropGallery }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill=""/></svg>
                                            </div>
                                            <button v-if="dropGallery" @click="dropGallery = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                                            <div v-if="dropGallery" class="relative">
                                                <ul class="border bg-gray-200 absolute top-1 -left-2 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                                    <li :class="{ current : galleryItem === galleryName }" class="nav-link hover:bg-bgBlue p-1.5 text-bgBlue hover:text-white rounded-md" v-for="(galleryItem, index) in galleryList" :key="index" @click="selectGallery(galleryItem.name)">
                                                        <a href="javascript:void(0)" @click="redirectBtn(galleryItem.link)" class="py-2 font-semibold">{{ galleryItem.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="hover:bg-bgBlue px-2 py-1.5 rounded cursor-pointer">
                                            <div class="flex items-center gap-2 text-black" v-bind:class="{ 'bg-bgBlue text-white p-1.5 rounded': isActive('sales-&-auction') || isActive('buy-&-rent') || isActive('custom-order') || isActive('exhibition') || isActive('exhibition-info') }" @click="dropService = !dropService">
                                                <span v-if="!isActive('sales-&-auction') && !isActive('buy-&-rent') && !isActive('custom-order') && !isActive('exhibition') && !isActive('exhibition-info')">Servies</span>
                                                <span v-if="isActive('sales-&-auction') ">Sales / Auction</span>
                                                <span v-if="isActive('buy-&-rent')">Buy / Rent</span>
                                                <span v-if="isActive('custom-order')">Custom Order</span>
                                                <span v-if="isActive('exhibition') || isActive('exhibition-info')">Exhibition</span>
                                                <svg class="w-2 lg:w-3.5 fill-current hover:text-white text-black flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropService }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill=""/></svg>
                                            </div>
                                            <button v-if="dropService" @click="dropService = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                                            <div v-if="dropService" class="relative">
                                                <ul class="border bg-gray-200 absolute top-1 -left-2 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                                    <li :class="{ current : serviceItem === galleryName }" class="hover:bg-bgBlue p-1.5 text-bgBlue hover:text-white rounded-md" v-for="(serviceItem, index) in serviceList" :key="index" @click="selectService(serviceItem.name)">
                                                        <a href="javascript:void(0)" @click="redirectBtn(serviceItem.link)" class="py-2 font-semibold">{{ serviceItem.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <a href="javascript:void(0)" @click="redirectBtn('/crowd-funding')" class="nav-link hover:bg-bgBlue px-2 py-1.5 rounded" :class="[{'bg-bgBlue text-white text-center' : isCrowdFunding}]">Crowd Funding</a>
                                        <a href="javascript:void(0)" @click="redirectBtn('/forum')" class="nav-link hover:bg-bgBlue px-2 py-1.5 rounded" :class="[{'bg-bgBlue text-white text-center' : isForum}]">Forum</a>
                                    </div>

                                    <div class="absolute inset-y-2 right-2">
                                        <button type="button" @click="mobileHeader = !mobileHeader">
                                            <svg class="ill-current text-black w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </header>
        
    </div>
    <div v-if="isConfirm">
        <Confirmation 
            :title="'Confirmation to redirect'"
			:message="'You are about to be redirected to the buyer-side application. Do you want to continue ?'"
			@closeModelBox = "closeModelBox"
			@deleteRecord = "confirmedBtn" 
        />
    </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import Confirmation from "./DeleteRecordModelBox.vue";
export default  ({
    props:[
        'isSearchPage',
        'isCardid',
        'isArtGallery',
    ],
    data: function() {
        return {
            isAuthenticated: false,
            // isArtGallery: false,
            mobileHeader: false,        
            // galleryName: 'Gallery',
            galleryList: [
                {name:'Art Gallery', link: '/art-gallery'},
                {name:'Photo gallery', link:'/photo-gallery'},
                {name:'Frame Gallery', link:'/frame-gallery'},
                {name:'Design Gallery', link:'/designers-gallery'},
            ],
            dropGallery: false, 

            // serviceName: 'Servies',
            serviceList: [
                {name:'Sale / auction', link:'/sales-and-auction'},
                {name:'Buy / Rent', link:'/buy-and-rent'},
                {name:'Custom Order', link:'/custom-order'},
                {name:'Exhibition', link:'/exhibition'},
            ],
            dropService: false, 

            langName: 'Eng',
            langList: [
                {name:'Eng'},
                {name:'Jap'},
            ],
            dropLang: false,
            dropUser: false,
            profile_image: '/images/user.webp',
            buyerBaseUrl: process.env.VUE_APP_SMART_ART_BUYER_URL,
            isConfirm:false,
            url:'',
        }
    },
    components: {
        Confirmation
    },
    computed: {
        ...mapGetters({
        }),
           
    },
    methods: {
         ...mapMutations({
        }),
        /*selectGallery(gallery) {
            this.galleryName = gallery;
        },*/
        /*selectService(option) {
            this.serviceName = option;
        },*/
        
        selectLang(option) {
            this.langName = option;
        },

        isActive(routeName) {
          return this.$route.name === routeName;
        },

        async getSiteSettingsClient(){
            clearInterval(this.intervalId);
            this.gIndicator('start');
            try{
                const res = await this.$store.dispatch("getSiteSettingsClient");
                let applicable_currency = res.data.applicable_currency || 'USD';
                localStorage.setItem("applicable_currency", applicable_currency);
            } catch(error){
                console.log("Error in response", error);
            }finally{
                this.gIndicator('stop');
            }
        },

         async getProfileDetails(){
            let emailid = localStorage.getItem('email');
            const payload = {
                email : emailid
            }
            try{
                const res = await this.$store.dispatch("myProfile/getProfileDetails",{payload: payload});
                if(res && res.profile_image){
                    this.profile_image = res.profile_image;
                }
            } catch(error){
                console.log("Error in response", error);
            }finally{
               
            }
        },

        logout(){
            localStorage.removeItem("sso_data","");
            localStorage.removeItem("ls_data","");
            localStorage.removeItem("localforage/smartartclient/authtoken","");
            window.location.href = process.env.VUE_APP_SMART_ART_SELLER_URL;
        },
        closeModelBox(){
            this.isConfirm = false;
            this.url = '';
        },
        redirectBtn(query){
            this.isConfirm = true;
            this.url = query;
        },
        confirmedBtn(){
            window.open(this.buyerBaseUrl+this.url);
            this.closeModelBox();
        }
    },
   

    mounted() {
        // if(window.location.pathname == '/art-gallery'){
        //     this.isArtGallery = 1
        // }
       
        // this.isArtGallery = localStorage.getItem('setActiveClass');
        this.getProfileDetails();

        this.isAuthenticated = this.isUserLoggedIn();
        this.getSiteSettingsClient();
    },
    
});
</script>
<style scoped>
    .active {
        background: #022770;
        color: #fff;
    }
    .activeCart {
        background: #022770;
        border-radius: 50px;
    }

    .galActive {
      background-color: blue;
    }


</style>