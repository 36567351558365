export default [
    {
        path: '/settings',
        component: () => import('../components/Settings.vue'),
        meta: {},
        children: [
            {
                path: '/settings/slack-integration',
                component: () => import('../components/partials/SlackIntegration.vue'),
                name: 'slack-integration',
            },
            {
                path: '/settings/general',
                component: () => import('../components/partials/General.vue'),
                name: 'general',
            },
        ]
    },
   

]