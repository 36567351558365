<template>
    <div class="relative space-y-4 " id="feedback-scroll">
        <img src="/images/feedback.webp" class="w-full h-[707px] 2xl:object-fit">
        <div class="absolute inset-y-0 w-full flex justify-center items-center">
            <div class="w-11/12 2xl:w-9/12 mx-auto my-6 pt-6 space-y-12">
                <div class="flex items-center justify-between gap-2">
                    <div class="w-[681px] border-b-2 border-white pb-4">
                        <label class="md:px-8 text-white font-semibold font-inter text-xl md:text-2xl lg:text-3xl xl:text-5xl uppercase">Reviews & Feedback</label>
                    </div>
                    <div class="flex w-[279px] h-[60px]">
                        <button @click="showAddFeedback(true)" class="w-full text-xs md:text-base text-white font-semibold flex items-center justify-center bg-bgReg shadow-btnShadow border border-white rounded-md uppercase transform hover:-translate-1 hover:scale-105 transition duration-100">Add Your Feedback</button>
                    </div>
                </div>
                <!-- :autoplayInterval="3000" -->
                <div class="card relative">
                    <Carousel :value="getFeedbackList" :num-visible="2" :num-scroll="1" :responsive-options="responsiveOptions" >
                        <template #item="{data}">
                            <div class="px-2 xl:px-4 flex justify-center">
                                <div class="px-2 w-full">
                                    <div class="bg-white rounded-feedCardRadius w-full xl:w-[560px] h-[299px] h-full flex items-center justify-center gap-2">
                                        <div class="w-[150px] lg:w-[39%] xl:w-[150px] h-[259px] relative flex justify-center items-center pl-5 md:pl-0">
                                        <div class="bg-feedBg w-[100px] h-[239px] rounded-feedBorderRadius"></div>
                                        <div class="absolute inset-0 bg-yellowBg w-[145px] h-[199px] my-auto rounded-feedBorderRadius">
                                            <div class="space-y-2 p-2">
                                                <div class="w-[125px] h-[125px]">
                                                    <img :src="data.profile_image ? data.profile_image_url : '/images/user.webp'" class="w-full h-full object-cover">
                                                </div>
                                                <label class="font-black text-sm flex justify-center h-5">{{data.first_name}}</label>
                                                <label class="font-normal text-xs flex justify-center ">Artist</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-[70%] xl:w-[310px] h-[205px] space-y-6 pl-5 md:pl-0 md:px-8 lg:px-2 py-2">
                                        <div class="w-full h-[125px] overflow-auto">
                                            <p class="font-normal text-sm">
                                                {{data.message}}
                                            </p>
                                        </div>
                                        <div>                                 
                                            <svg class="fill-current text-yellowStar" xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="m20 29.5-6.875 4.125q-.333.167-.625.146-.292-.021-.542-.188-.25-.166-.396-.458-.145-.292-.02-.625l1.791-7.833-6.041-5.292q-.292-.25-.354-.542-.063-.291.02-.583.084-.292.313-.479.229-.188.604-.229l8-.709L19 9.417q.167-.292.438-.459.27-.166.562-.166.292 0 .562.166.271.167.438.459l3.125 7.416 8 .709q.375.041.604.229.229.187.313.479.083.292.02.583-.062.292-.354.542l-6.041 5.292L28.5 32.5q.083.333-.062.625-.146.292-.396.458-.25.167-.542.188-.292.021-.625-.146Z"/></svg>
                                        </div>
                                    </div>
                                    </div>

                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>

                <div class="flex justify-between">
                    <label class="flex"></label>
                    <label class="flex w-[180px] h-[60px]">
                        <a href="#" class="w-full text-white font-semibold flex items-center justify-center bg-bgReg shadow-btnShadow border border-white rounded-md uppercase transform hover:-translate-1 hover:scale-105 transition duration-100">
                        View All
                        </a>
                    </label>
                </div>
               
            </div>
        </div>
        
        <div v-if="getShowAddFeedback">
            <AddFeedback />
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import Carousel from 'primevue/carousel';

import AddFeedback from './partials/AddFeedback.vue'
export default  ({
    data: function() {
        return {
            responsiveOptions:[
                {
                    breakpoint: '1199px',
                    numVisible: 2,
                    numScroll: 1
                },
                {
                    breakpoint: '991px',
                    numVisible: 1,
                    numScroll: 1
                },
                {
                    breakpoint: '767px',
                    numVisible: 1,
                    numScroll: 1
                }
            ],
        }
    },
    components: {
      AddFeedback,
      Carousel,
    },
    computed: {
     ...mapGetters({
        getShowAddFeedback: 'feedback/getShowAddFeedback',  
        getFeedbackList:'feedback/getFeedbackList',       
    }),

    },

    methods: {
        ...mapMutations({
            'showAddFeedback':'feedback/showAddFeedback',
        }),
         ...mapActions({
            fetchFeedbackList:'feedback/fetchFeedbackList',
        }),
         fetchData(){
            try{
                this.gIndicator('start');
                this.fetchFeedbackList().then((response) => {
                    if(response){
                        this.gIndicator('stop');
                    }
                })
                .catch((err) => {
                });
            }catch(error){
                this.gIndicator('stop');
            }
        },

    },
   

    mounted() {
        this.fetchData();
    },
    
});
</script>
<style>
    
</style>