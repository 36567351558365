export default [
	{
        path: '/design-library',
        component: () => import('../components/DesignLibraryParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/design-library',
                component: () => import('../components/DesignLibrary.vue'),
                name: 'design-library',
            },
            {
                path: '/add-design',
                component: () => import('../components/partials/DesignAddEditView.vue'),
                name: 'add-design',
            },
            {
                path: '/edit-design/:id',
                component: () => import('../components/partials/DesignAddEditView.vue'),
                name: 'edit-design',
            },
            {
                path: '/view-design/:id',
                component: () => import('../components/partials/DesignAddEditView.vue'),
                name: 'view-design',
            },
            
        ]
    },

]