import localforage from 'localforage'
import { isEmpty } from 'lodash'

export const getProfileDetails = ({ commit, dispatch }, { payload, context }) => {
    let userAuthToken = localStorage.getItem('localforage/smartartclient/authtoken');
    let query = payload && payload.edit ? '?edit=true' : '';
    if(userAuthToken !== null && userAuthToken != ""){
        let emailid = payload.email;
        return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/profile-details/' + emailid+query).then((response) => {
            return response.data.details;
        })
    }
}

export const saveProfileDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/sso-user/update-details/' + payload.old_email_id, payload).then((response) => {
        if(response.data.success){
            return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/update-profile-details', payload).then((response) => {
                commit('SET_ALERT', 'success', { root: true })
                commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                return response;
            }).catch((error) => {
                commit('SET_ALERT', 'warning', { root: true })
                commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                context.errors = error.response.data.errors
                return false;
            })
        }
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

export const updateProfilePic = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/update-profile-pic', payload,{
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
        },
    }).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

export const updateBankPassbookPhoto = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/update-bank-passbook-photo', payload,{
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
        },
    }).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

export const deleteProfileImage = ({ commit, dispatch }, { payload, context }) => {
    let email = payload.email;
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/remove-profile-pic/' + email).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

export const deleteBankPassbookImage = ({ commit, dispatch }, { payload, context }) => {
    let id = payload.id;
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/deleteBankPassbookImage/' + id).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}