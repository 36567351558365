export default [
	{
		path: '/crowdfunding-list',
		component: () => import('../components/ParentCrowdfundingList.vue'),
		meta: {                  
		},
		children:[
			{
				path: '/crowdfunding-list',
				component: () => import('../components/CrowdfundingList.vue'),
				name: 'crowdfunding-list',
			},
			{
				path: '/purchased-arts-list',
				component: () => import('../components/PurchasedArtsList.vue'),
				name: 'purchased-arts-list',
			},
			{
				path: '/request-crowdfunding',
				component: () => import('../components/partials/RequestCrowdfunding.vue'),
				name: 'request-crowdfunding',
			},
			{
				path: '/crowdfunding/view-info/:id',
				component: () => import('../components/partials/ViewInfoCrowdfunding.vue'),
				name: 'view-info',
			},

			{
				path: '/purchased/view-info/:id',
				component: () => import('../components/partials/ViewPurchasedInfo.vue'),
				name: 'view-purchased-info',
			},
			{
				path: '/purchased/view-user-profile',
				component: () => import('../components/partials/ViewUserProfile.vue'),
				name: 'view-user-profile',
			},
		]

    },
	{
		path: '/cancel-crowdfunding-request-list',
		component: () => import('../components/partials/CancelCrowdfundingRequestList.vue'),
		name: 'cancel-crowdfunding-request',
	},
	{
		path: '/edit-crowdfunding-art/:id',
		component: () => import('../components/partials/MyTaskEditArtCrowdfundingModelBox.vue'),
		name: 'edit-crowdfunding-art',
	},
	{
		path: '/cancelled-order-list',
		component: () => import('../components/partials/CancelledOrderList.vue'),
		name: 'cancelled-order-list',
	},
	{
		path: '/cancelled-order/view/:id',
		component: () => import('../components/partials/ViewInfoCancelledOrder.vue'),
		name: 'cancelled-order-view',
	},
]