export const getPaypalModel = (state) => {
	return state.financeAudit.updatePaypalModel;
}

export const getBankTransferModel = (state) => {
	return state.financeAudit.updateBankDetailsModel;
}

export const getEditStripePaymentModel = (state) => {
	return state.financeAudit.updateStripeModel;
}

export const getPaymentType = (state) => {
    return  state.financeAudit.paymentType;
}

export const getDisableGatewayModel = (state) => {
	return state.financeAudit.disableGatewayModel
}

export const getFinanceAuditSettingsData = (state) => {
	return state.financeAudit;
}

export const getSlackWebhookData = (state) => {
    return state.slackWebhook.webhookData;
}
export const getSlackWebhookLink = (state) => {
    return state.slackWebhook.webhookLinks;
}
export const getSlackWebhookMeta = (state) => {
    return state.slackWebhook.webhookMeta;
}
export const getShowAddNewSlack = (state) => {
	return state.slackWebhook.showAddNewSlack
}
export const getEditSlackUrlId = (state) => {
	return state.slackWebhook.slackUrlId;
}
export const getModuleId = (state) => {
    return state.slackWebhook;
}
export const getSlackModuleListData = (state) => {
	return state.slackModule.data;
}
export const getShowDeleteSlackAndModule = (state) => {
	return state.showDeleteSlackAndModule
}
