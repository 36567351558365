export default [
	{
        path: '/frame-library',
        component: () => import('../components/FrameLibraryParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/frame-library',
                component: () => import('../components/FrameLibrary.vue'),
                name: 'frame-library',
            },
            {
                path: '/add-frame',
                component: () => import('../components/partials/FrameAddEditView.vue'),
                name: 'add-frame',
            },
            {
                path: '/edit-frame/:id',
                component: () => import('../components/partials/FrameAddEditView.vue'),
                name: 'edit-frame',
            },
            {
                path: '/view-frame/:id',
                component: () => import('../components/partials/FrameAddEditView.vue'),
                name: 'view-frame',
            },
            
        ]
    },

]