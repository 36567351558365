export const getSearchListData = ({ commit, dispatch }, query='') => {
    if(query == ''){
        query = '?perPage=1000';
    }
    let url = process.env.VUE_APP_SMART_ART_API_URL + 'api/search/list'+ query;
    return window.axios.get(url).then((response) => {
        commit('SET_SEARCH_LIST_DATA', response.data)
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
};