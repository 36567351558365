export default [
	{
        path: '/search',
        component: () => import('../components/SearchParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/search',
                component: () => import('../components/Search.vue'),
                name: 'search',
            },

            {
                path: '/search/buy-product',
                component: () => import('../../cart/components/partials/CartBuyProduct.vue'),
                name: 'search-buy-product',
                    
            },
            
        ]
    },
   
]