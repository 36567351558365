export const getProductCategory = ({ commit  }, payload) => {
    return window.axios.get (process.env.VUE_APP_SMART_ART_API_URL +'api/get-product-categories', payload).then((response) => {
        return response.data
    }).catch((error) => {
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return error.response.data;
    })
}
export const getProductCounties = ({ commit  }, payload) => {
    return window.axios.get (process.env.VUE_APP_SMART_ART_API_URL +'api/get-product-countries', payload).then((response) => {
        return response.data
    }).catch((error) => {
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return error.response.data;
    })
}