<template>
	<div>  
		<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
		<div>   
			<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex">
				<div class="relative w-full mx-4 md:mx-0">       
					<div class="shadow-lg rounded-xl relative flex  bg-[#F5F5F5] outline-none focus:outline-none max-w-lg mx-auto">        
						<div class="w-full">
							<div class="flex items-center justify-between px-5 py-2 text-base font-semibold rounded-t-xl text-white bg-gradient-to-r from-[#400045] to-[#D605E6]">
								<div >{{ title }}</div> 
								<div class="flex justify-center items-center">
									<button class="" type="button" @click="closeModelBox()">
										<svg class="" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 .424c-5.522 0-10 4.305-10 9.615 0 5.31 4.478 9.616 10 9.616s10-4.306 10-9.616S15.522.424 10 .424Zm3.692 13.268-1.473-.006L10 11.142l-2.217 2.542-1.475.006a.174.174 0 0 1-.179-.172c0-.04.016-.08.043-.111l2.904-3.327-2.904-3.325a.175.175 0 0 1-.043-.111c0-.095.08-.172.179-.172l1.475.007L10 9.022l2.216-2.541 1.474-.007c.098 0 .178.075.178.172 0 .04-.015.08-.042.112l-2.9 3.324 2.902 3.327c.027.032.043.07.043.111 0 .095-.08.172-.179.172Z" fill="#fff"/></svg>
									</button>
								</div>
							</div>

							<div class="p-6 space-y-6">
								<div class="text-center text-base font-nunito h-10 items-center flex justify-center">
									{{ message }}
								</div>

								<div class="flex justify-center gap-5">
									<button type="button" @click="closeModelBox()" class="w-28 md:w-36 bg-white shadow-logoShadow rounded-full h-8 md:h-10 flex justify-center items-center text-black font-semibold hover:bg-gray-200 border border-gray-300">
										No
									</button>
									<button type="button" :disabled="isDisable" @click="deleteRecord()" class="w-28 md:w-36  shadow-logoShadow rounded-full h-8 md:h-10 flex justify-center items-center" :class="[isDisable ? 'cursor-not-allowed bg-gray-200 border border-gray-300' : 'text-white font-semibold bg-greenBg hover:bg-green-700 border']">
										<svg v-if="isDisable" class="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
										<span v-if="!isDisable" class="flex items-center gap-2">Yes</span>
									</button>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    import { isEmpty } from 'lodash'
    export default {
        props: {
            title: '',
            message: '',
        },
        data(){
            return{
                isDisable: false
            };
        },
        components: {

        },
        computed: {

        },
        methods: {
            closeModelBox(){
                this.$emit("closeModelBox");
            },
            deleteRecord(){
                this.isDisable = true;
                this.$emit("deleteRecord");
            }
        },
        mounted() {
            
        }
    };
</script>
