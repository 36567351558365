export const getCancelSubscription = (state) => {
	return state.showCancelSubscription
}

export const getDeletePayment = (state) => {
	return state.showDeletePayment
}
export const getCrowdFunding = (state) => {
	return state.crowdfunding.data;
}
export const getCrowdFundingListMeta = (state) => {
	return state.crowdfunding.meta;
}
export const getCrowdFundingListLinks = (state) => {
	return state.crowdfunding.links;
}
export const getCrowdFundingCurrentPage = (state) => {
	return state.crowdfunding.current_page;
}