export default {    
    getHeaderImage() {
		return state.isHeaderImage
	},

	getIsSignPage() {
		return state.isSignPage
	},

	getIsAuth() {
		return state.isAuth
	},

	getIsAuthBuyer() {
		return state.isAuthBuyer
	},

    getDkToastSuccess(state) {
        return state.dkToastConfig.success
    },
    getDkToastError(state) {
        return state.dkToastConfig.error
    },
    getDkToastWarning(state) {
        return state.dkToastConfig.warning
    },
}