export default [
	{
        path: '/photo-library',
        component: () => import('../components/PhotoLibraryParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/photo-library',
                component: () => import('../components/PhotoLibrary.vue'),
                name: 'photo-library',
            },
            {
                path: '/add-photo',
                component: () => import('../components/partials/PhotoAddEditView.vue'),
                name: 'add-photo',
            },
            {
                path: '/edit-photo/:id',
                component: () => import('../components/partials/PhotoAddEditView.vue'),
                name: 'edit-photo',
            },
            {
                path: '/view-photo/:id',
                component: () => import('../components/partials/PhotoAddEditView.vue'),
                name: 'view-photo',
            },
            
        ]
    },

]