export const store = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/store/' + payload.get("id"), payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getProductTypes = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/product-types').then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getProductList = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/product-list/'+payload.type+'?available_for='+payload.art_available_for).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getCrowdFundingDetailsById = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/crowd-funding/'+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        context.gIndicator('stop');
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const deleteArtProduct = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + 'api/delete-product/'+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        context.gIndicator('stop');
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getCancelCrowdFundingList = ({ commit, dispatch }, query = '') => {
    if(query){
        query = query+'&is_deleted=1';
    }else{
        query = '?is_deleted=1';
    }
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/crowd-funding-list'+ query).then((response) => {
        commit('setCancelCrowdFunding', response.data);
        return true;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getProductDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-art-peice-data/'+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        context.gIndicator('stop');
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const updateProductDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/update-art-piece-data/'+payload.id, payload).then((response) => {
        return response.data;
    }).catch((error) => {
        context.gIndicator('stop');
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getCrowdFundingList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/crowd-funding-list'+ query).then((response) => {
        commit('setCrowdFunding', response.data);
        return true;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const cancelCrowdFunding = ({ commit, dispatch }, {payload,context}) => {
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + 'api/crowd-funding/'+ payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        context.gIndicator('stop');
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getProfileDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-profile-details?id='+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const setArtPieceStatus = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/update-art-piece-status', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const storeCompletedArtImages = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/completed-art-images', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const saveTrackingOrderDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/tracking-order-details', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const addCommentRequest = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/add-comment', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const deliverStatusImage= ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/deliver-status-image', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const submitMessage= ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/crowdfunding-messages', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const totalFundAmount = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/seller-total-funds').then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getCancelOrderList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/cancelled-list'+ query).then((response) => {
        commit('setCancelOrder', response.data);
        commit('setCancelOrderReturnToBuyerFundsInfo',response.data.funds_return_to_buyer_info);
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getCancelOrderDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/cancelled-order-details/'+payload.id+'?order_id='+payload.order_id).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}