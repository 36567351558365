export const getShowAuth = (state) => {
	return state.showAuth
}
export const getShowAuthModel = (state) => {
	return state.showAuthModel
}
export const getShowForgetPassword = (state) => {
	return state.showForgetPassword
}

export const getReturnPolicy = (state) => {
	return state.showReturnPolicyModel
}

export const getShowChangePassword = (state) => {
	return state.showChangePassword
}
export const getReturnPolicyValue = (state) => {
	return state.setReturnPolicyValue
}