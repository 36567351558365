export default [
	{
        path: '/art-gallery',
        component: () => import('../components/ArtGalleryParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/art-gallery',
                component: () => import('../components/ArtGallery.vue'),
                name: 'art-gallery',
            },
            {
                path: '/art-users',
                component: () => import('../components/artGalleryPartials/UserArtists.vue'),
                name: 'art-users',
                meta: {                  
                }
            },
            {
                path: '/art-user-profile/:id',
                component: () => import('../components/artGalleryPartials/ArtUserProfile.vue'),
                name: 'art-user-profile',
                meta: {                  
                }
            },
        ]
    },
    
    {
        path: '/photo-gallery',
        component: () => import('../components/PhotoGalleryParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/photo-gallery',
                component: () => import('../components/PhotoGallery.vue'),
                name: 'photo-gallery',
            },
            {
                path: '/photographer-list',
                component: () => import('../components/photoGalleryPartials/PhotographersList.vue'),
                name: 'photographer-list',
            },
            {
                path: '/photographer-profile/:id',
                component: () => import('../components/photoGalleryPartials/PhotographerProfile.vue'),
                name: 'photographer-profile',

            },
        ]
    },
    
    {
        path: '/frame-gallery',
        component: () => import('../components/FrameGalleryParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/frame-gallery',
                component: () => import('../components/FrameGallery.vue'),
                name: 'frame-gallery',
            },
             {
                path: '/frame-vendors',
                component: () => import('../components/frameGalleryPartials/FrameVendors.vue'),
                name: 'frame-vendors',
            },
            {
                path: '/frame-vendor-profile/:id',
                component: () => import('../components/frameGalleryPartials/FrameVendorProfile.vue'),
                name: 'frame-vendor-profile',
            },
        ]
    },

    {
        path: '/designers-gallery',
        component: () => import('../components/DesignGalleryParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/designers-gallery',
                component: () => import('../components/DesignGallery.vue'),
                name: 'designers-gallery',
            },
             {
                path: '/designers-list',
                component: () => import('../components/designGalleryPartials/DesignList.vue'),
                name: 'designers-list',
            },
            {
                path: '/designers-profile/:id',
                component: () => import('../components/designGalleryPartials/DesignProfile.vue'),
                name: 'designers-profile',
            },
        ]
    },
   
    
       
]