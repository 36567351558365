export const getProductCategoiesList = (state) => {
	return state.list.prodcutCategoeis;
}

export const getListData = (state) => {
	return state.list.data;
}

export const getListLinks = (state) => {
	return state.list.links;
}

export const getListMeta = (state) => {
	return state.list.meta;
}

export const getCurrentPage = (state) => {
	return state.list.current_page;
}

export const getDeletedCategoiesList = (state) => {
	return state.deleted_list.prodcutCategoeis;
}

export const getDeletedListData = (state) => {
	return state.deleted_list.data;
}

export const getDeletedListLinks = (state) => {
	return state.deleted_list.links;
}

export const getDeletedListMeta = (state) => {
	return state.deleted_list.meta;
}

export const getDeleteListCurrentPage = (state) => {
	return state.deleted_list.current_page;
}