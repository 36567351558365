// Add feedback
export const addFeedback = ({ commit }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/feedback-store', payload).then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data;
    })
    .catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}
// 

export const fetchFeedbackList = ({ commit, dispatch }, query = '') => {
    if(query){
        query = query + '&status=1';
    }else{
        query = '?status=1';
    }
    return window.axios.get (process.env.VUE_APP_SMART_ART_API_URL + 'api/feedback-list' + query).then((response) => {
        commit('setFeedbackList', response);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return;
    });
}