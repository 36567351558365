export default [
	{
        path: '/sales-&-auction',
        component: () => import('../components/SalesAndAuction.vue'),
        name: 'sales-&-auction',
        meta: {                  
        }
    },
    {
        path: '/buy-&-rent',
        component: () => import('../components/BuyRent.vue'),
        name: 'buy-&-rent',
        meta: {                  
        }
    },
    {
        path: '/custom-order',
        component: () => import('../components/CustomOrder.vue'),
        name: 'custom-order',
        meta: {                  
        }
    },
    {
        path: '/order-now',
        component: () => import('../components/ParentOrderNow.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/order-now/art',
                component: () => import('../components/OrderNowArt.vue'),
                name: 'order-now-art',
            },
            {
                path: '/order-now/photo',
                component: () => import('../components/OrderNowPhoto.vue'),
                name: 'order-now-photo',
            },
            {
                path: '/order-now/design',
                component: () => import('../components/OrderNowDesign.vue'),
                name: 'order-now-design',
            },
        ]
    },

    
    {
        path: '/exhibition',
        component: () => import('../components/Exhibition.vue'),
        name: 'exhibition',
        meta: {                  
        }
    },
    {
        path: '/exhibition-info',
        component: () => import('../components/partials/ExhibitionInfo.vue'),
        name: 'exhibition-info',
        meta: {                  
        }
    },
   
]