export const showInviteRefferals = (state, flag) => {
	state.showInviteRefferals = flag
}

export const showDeleteRefferal = (state, flag) => {
	state.showDeleteRefferal = flag
}
export const SET_REFERRAL_LIST = (state, data) => {
	state.referralList.data = data.data.data;
    state.referralList.links = data.data.links;
	state.referralList.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}