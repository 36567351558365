export const submitInviteReferrals = ({ commit  }, payload) => {
    return window.axios.post (process.env.VUE_APP_SMART_ART_API_URL +'api/referrals', payload).then((response) => {
        return response.data
    }).catch((error) => {
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return error.response.data;
    })
}

export const fileSize = ({ commit  }, payload) => {
    return window.axios.post (process.env.VUE_APP_SMART_ART_API_URL +'api/file-size', payload).then((response) => {
        return response.data
    }).catch((error) => {
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return error.response.data;
    })
}
export const fetchReferralList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/referrals' + query).then((response) => {
        commit('SET_REFERRAL_LIST', response.data)
        return true
    }).catch((error) => {
        return false
    })
}
export const deleteReferralPlan = ({ commit}, id) => {
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + 'api/referrals/'+id).then((response) => {
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};

export const sendReminderReferrals= ({ commit  }, {context, payload }) => {
    return window.axios.post (process.env.VUE_APP_SMART_ART_API_URL +'api/referral-send-reminder', payload).then((response) => {
        return response.data;
    }).catch((error) => {
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        context.isLoading = false;
        return error.response.data;
    })
}