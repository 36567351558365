// put your main mutations here
import { isEmpty } from 'lodash'

const UNSET_ALERT = (state, alertName) => {
	state.alerts[alertName] = false
}

const SET_ALERT = (state, alertName) => {
	// Reset all alerts
	Object.entries(state.alerts).forEach((key, value) => {
		state.alerts[key] = false
	})
	state.alerts[alertName] = true

	// Fadeout the alert in configured time
	setTimeout(function() {
		state.alerts[alertName] = false
    }, window.alertDuration);
}

const SET_ALERT_MESSAGE = (state, message) => {
	state.alerts.message = message
}

// put your main mutations here
export default {
    UNSET_ALERT,
	SET_ALERT,
	SET_ALERT_MESSAGE,
    
	isBgImage(state, flag) {
		state.isHeaderImage = flag
	},

	isNotAuthenticated(state, flag) {
		state.isSignPage = flag
	},

	isAuthenticated(state, flag) {
		state.isAuth = flag
	},

	isAuthenticatedBuyer(state, flag) {
		state.isAuthBuyer = flag
	},
}
