export const getCancelCrowdFunding = (state) => {
	return state.crowdfunding.data;
}
export const getCancelCrowdFundingListMeta = (state) => {
	return state.crowdfunding.meta;
}
export const getCancelCrowdFundingListLinks = (state) => {
	return state.crowdfunding.links;
}
export const getCancelCrowdFundingCurrentPage = (state) => {
	return state.crowdfunding.current_page;
}
export const getCrowdFunding = (state) => {
	return state.crowdfunding.data;
}
export const getCrowdFundingListMeta = (state) => {
	return state.crowdfunding.meta;
}
export const getCrowdFundingListLinks = (state) => {
	return state.crowdfunding.links;
}
export const getCrowdFundingCurrentPage = (state) => {
	return state.crowdfunding.current_page;
}
export const getCancelOrder = (state) => {
	return state.cancelOrder.data;
}
export const getCancelOrderListMeta = (state) => {
	return state.cancelOrder.meta;
}
export const getCancelOrderListLinks = (state) => {
	return state.cancelOrder.links;
}
export const getCancelOrderCurrentPage = (state) => {
	return state.cancelOrder.current_page;
}
export const getCancelOrderReturnToBuyerFundsInfo = (state) => {
	return state.cancelOrderReturnToBuyerFundsInfo;
}