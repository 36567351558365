export default {
	showInviteRefferals: false,
	showDeleteRefferal: false,
	referralList: {
		data: null,
		links: null,
		meta: null,
		referralId:''
	},
}
