export const SET_PRODUCTS_LIST_DATA = (state, data) => {
    state.list.prodcutCategoeis = data.prodcutCategoeis;
    state.list.data = data.data.data.data;
    state.list.links = data.data.data.links;
    state.list.meta = {
      current_page: data.data.data.current_page,
      from: data.data.data.from,
      to:  data.data.data.to,
      total: data.data.data.total,
      perPage: data.data.data.per_page
  }
}

export const SET_PRODUCTS_DELETED_LIST_DATA = (state, data) => {
  state.deleted_list.prodcutCategoeis = data.prodcutCategoeis;
  state.deleted_list.data = data.data.data.data;
  state.deleted_list.links = data.data.data.links;
  state.deleted_list.meta = {
    current_page: data.data.data.current_page,
    from: data.data.data.from,
    to:  data.data.data.to,
    total: data.data.data.total,
    perPage: data.data.data.per_page
}
}